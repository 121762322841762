import React, { useState } from "react";
import axios from "axios";
import "./style.css"; // Import your CSS file

function Contact() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: ""
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("https://fabusho.com/send-email.php", form);

      if (response.data.status === "success") {
        setStatus("Your message has been sent!");
      }
    } catch (error) {
      console.error(error);
      setStatus("There was an error. Please try again later.");
    }
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Me</h1>
      <p>
        I'm currently open for opportunities as a consulting engineer, and I'm eager to discuss potential collaborations with you.
        Whether it's a small-scale project or a larger endeavor, I'm committed to
        understanding your requirements thoroughly and bringing innovative solutions
        to every aspect of the project. Let’s talk.
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <div><label htmlFor="name">Name</label></div>
          <input
            type="text"
            id="name"
            name="name"
            value={form.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <div><label htmlFor="email">Email</label></div>
          <input
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <div><label htmlFor="message">Message</label></div>
          <textarea
            id="message"
            name="message"
            value={form.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      {status && <p className="status-message">{status}</p>}
    </div>
  );
}

export default Contact;
