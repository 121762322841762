import React, { useState, useEffect } from 'react';
import './style.css';

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch('/services.json')
      .then(response => response.json())
      .then(data => setServices(data))
      .catch(error => console.error('Error loading services:', error));
  }, []);

  return (
    <div className="services-container">
      <div className="services-title">
        <h1>Services</h1>
      </div>
      <div className="services-boxes">
        {services.map(service => (
          <div key={service.id} className="service-box">
            <img src={service.image} alt={service.title} className="service-image" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
