import React, { useState, useEffect } from 'react';
import './style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Skills = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [skills, setSkills] = useState({ IT: [], CivilEngineering: [] });

  useEffect(() => {
    fetch('/skills.json')
      .then((response) => response.json())
      .then((data) => setSkills(data))
      .catch((error) => console.error('Error fetching skills data:', error));
  }, []);

  const renderSkill = (skill) => (
    <div className="skill" key={skill.title}>
      <span className="skill-title">{skill.title}</span>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${skill.percentage}%` }}>
          <span className="progress-label">{`${skill.percentage}%`}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="skills-container">
      <div className='skills-title'>
        <h1>Skills</h1>
      </div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>IT and Software</Tab>
          <Tab>Civil Engineering</Tab>
        </TabList>

        <TabPanel>
          <div className="tab-content">
            <div className="progress-lines">
              <div className="line" data-label="Basic" style={{ height: '25%' }}></div>
              <div className="line" data-label="Good" style={{ height: '50%' }}></div>
              <div className="line" data-label="Expert" style={{ height: '75%' }}></div>
            </div>
            {skills.IT.map(renderSkill)}
          </div>
        </TabPanel>

        <TabPanel>
          <div className="tab-content">
            <div className="progress-lines">
              <div className="line" data-label="Basic" style={{ height: '25%' }}></div>
              <div className="line" data-label="Good" style={{ height: '50%' }}></div>
              <div className="line" data-label="Expert" style={{ height: '75%' }}></div>
            </div>
            {skills.CivilEngineering.map(renderSkill)}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Skills;
