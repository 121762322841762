// src/components/Header/index.js
import React from 'react';
import './style.css';
import Brand from './Brand';
import Navbar from './Navbar';

const Header = () => {
  return (
    <header className="header">
      <div className="navbar-wrapper">
        <Brand />
        <Navbar />
      </div>
      <div className="header-titles">
        <p className='headerMainTitle'>Engineering Innovative Solutions</p>
        <p className='headerSubTitle'>Mastering the Intersection of Technology and Engineering:</p>
        <p className='headerSubTitle'>Empowering Innovation and Design Excellence.</p>
        <button className='header-btn'>Let's Work Together</button>
      </div>
    </header>
  );
};

export default Header;
