// src/components/Footer.js
import React from 'react';
import './style.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 fabusho. All rights reserved.</p>
        <p>Contact us at: <a href="mailto:info@yourcompany.com">info@fabusho.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;
