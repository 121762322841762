import React from 'react';
import './style.css';

const Brand = () => {
  return (
    <div className="brand">
      <img src="/assets/images/logo.svg" alt="Logo" className="logo" />
      <span className="brand-name">fabusho</span>
    </div>
  );
};

export default Brand;
