import React from "react";
import './style.css'

const About = () => {
    return (
        <div className="about">
            <h1 className="about-title">About Me</h1>
            <div className="about-container">
                <div className="about-img">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/aboutMe.png`} alt="My Profile" />
                </div>
                <div className="about-intro">
                    <p>
                    I'm Fathi Abushoglin, an experienced full-stack software designer and developer, merging my civil engineering background with digital solutions. I prioritize delivering exceptional results and providing valuable consultation to optimize software and civil engineering productivity. Offering personalized software development and structural engineering consulting services, I aim to forge lasting partnerships grounded in trust and excellence. 
                    Thank you for considering collaboration with me as your ally in innovation and success. I'm excited about the opportunity to work together and achieve remarkable outcomes.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default About;