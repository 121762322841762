import React from 'react';
import './style.css';
import About from '../Content/About';
import Skills from '../Content/Skills';
import Services from '../Content/Services';
import Contact from '../Content/Contact';

const Home = () => {
  return (
    <div className="home">
      <About />
      <Skills />
      <Services />
      <Contact />
    </div>
  );
};

export default Home;
